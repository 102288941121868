declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

const thanks = document.querySelector<HTMLDivElement>('.modal-thanks');
const modal = document.querySelector<HTMLDivElement>('.modal-callback');

const form = document.querySelector<HTMLFormElement>('.callback');
const checkbox = form?.querySelector<HTMLInputElement>(
  'input[type="checkbox"]'
);
const nameInput = document.querySelector<HTMLInputElement>(
  '.callback__name input'
);
const phoneInput = document.querySelector<HTMLInputElement>(
  '.callback__phone input'
);

const initModal = () => {
  const headerButton =
    document.querySelector<HTMLButtonElement>('.header__callback');
  const footerButton =
    document.querySelector<HTMLButtonElement>('.footer__callback');
  const buttons = [headerButton, footerButton];
  buttons.forEach((item) => {
    item?.addEventListener('click', () => {
      modal?.classList.add('active');
      document.body.classList.add('overflow-hidden');
    });
  });
};

const getSubmitFormData = () => {
  const source = document.body.dataset.source;

  const formData = new FormData();
  formData.append('form', 'callback');
  formData.append('name', nameInput!.value);
  formData.append('phone', phoneInput!.value);
  formData.append('source', source || '');
  return formData;
};

const resetForm = () => {
  nameInput!.value = '';
  phoneInput!.value = '';
  checkbox!.checked = false;
};

const initSubmit = () => {
  form?.addEventListener('submit', async (event) => {
    event.preventDefault();
    const formData = getSubmitFormData();
    await fetch('/ajax/_forms.php', {
      method: 'POST',
      body: formData,
    });

    ym(73424854, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3249506', type: 'reachGoal', goal: 'arrows' });

    resetForm();
    modal?.classList.remove('active');
    thanks?.classList.add('active');
    setTimeout(() => {
      thanks?.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    }, 3000);
  });
};

export default {
  init: () => {
    initModal();
    initSubmit();
  },
};

import Pikaday from 'pikaday';
import { i18nRus } from './constants';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

const thanks = document.querySelector<HTMLDivElement>('.modal-thanks');
const quizWrapper = document.querySelector<HTMLDivElement>('.quiz__wrapper');
const contacts = document.querySelector<HTMLDivElement>('.contacts');
const slider = document.querySelector<HTMLDivElement>('.quiz__slider');
const step = document.querySelector<HTMLSpanElement>('.quiz__steps span');
const form1 = document.querySelector<HTMLFormElement>('.section-1 form');
const form2 = document.querySelector<HTMLFormElement>('.section-2 form');
const form3 = document.querySelector<HTMLFormElement>('.section-3 form');
const checkbox = form3?.querySelector<HTMLInputElement>(
  'input[type="checkbox"]'
);
const prevButton2 = document.querySelector<HTMLButtonElement>(
  '.section-2 .quiz__prev'
);
const startInput =
  document.querySelector<HTMLInputElement>('.quiz__start input');
const endInput = document.querySelector<HTMLInputElement>('.quiz__end input');
const adultsSelect = document.querySelector<HTMLSelectElement>(
  '.quiz__adults select'
);
const childrenSelect = document.querySelector<HTMLSelectElement>(
  '.quiz__children select'
);
const years = document.querySelector<HTMLDivElement>('.quiz__years .years');
const yearsBlock = document.querySelector<HTMLDivElement>(
  '.quiz__years .years__block'
);
const yearsDropdown = document.querySelector<HTMLDivElement>(
  '.quiz__years .years__dropdown'
);
const yearsItems = document.querySelector<HTMLDivElement>(
  '.quiz__years .years__items'
);
const yearsContainer = document.querySelector<HTMLDivElement>(
  '.quiz__years .years__container'
);
const yearsConfirm = document.querySelector<HTMLDivElement>(
  '.quiz__years .years__confirm'
);
const nameInput = document.querySelector<HTMLInputElement>('.quiz__name input');
const phoneInput =
  document.querySelector<HTMLInputElement>('.quiz__phone input');

let startPicker: Pikaday;
let endPicker: Pikaday;

const setStep = (num: number) => (step!.textContent = `${num}`);

const makeSelect = (num: number, yearsItems: HTMLDivElement) => {
  const select = document.createElement('select');
  select.innerHTML = `
    <option value="0">0 лет</option>
    <option value="1">1 год</option>
    <option value="2">2 года</option>
    <option value="3">3 года</option>
    <option value="4">4 года</option>
    <option value="5">5 лет</option>
    <option value="6">6 лет</option>
    <option value="7">7 лет</option>
    <option value="8">8 лет</option>
    <option value="9">9 лет</option>
    <option value="10">10 лет</option>
    <option value="11">11 лет</option>
    <option value="12">12 лет</option>
    <option value="13">13 лет</option>
    <option value="14">14 лет</option>
    <option value="15">15 лет</option>
    <option value="16">16 лет</option>
    <option value="17">17 лет</option>
  `;
  select.addEventListener('change', () => {
    yearsItems!.children[num].textContent = select.value;
  });
  return select;
};

export const makeChild = (num: number, yearsItems: HTMLDivElement) => {
  const item = document.createElement('div');
  item.classList.add('years__item');
  const title = document.createElement('strong');
  title.classList.add('years__title');
  title.textContent = `Ребенок ${num + 1}`;
  const selectLabel = document.createElement('label');
  selectLabel.classList.add('years__select');
  const select = makeSelect(num, yearsItems!);
  item.appendChild(title);
  selectLabel.appendChild(select);
  item.appendChild(selectLabel);
  return item;
};

const addChild = (num: number) => {
  yearsContainer?.appendChild(makeChild(num, yearsItems!));
  const span = document.createElement('span');
  span.textContent = '0';
  yearsItems?.appendChild(span);
};

const removeChild = () => {
  yearsContainer?.removeChild(yearsContainer.lastChild!);
  yearsItems?.removeChild(yearsItems.lastChild!);
  if (!yearsItems?.children.length) {
    yearsDropdown?.classList.remove('active');
  }
};

const initDates = () => {
  endInput?.addEventListener('keydown', (event) => event.preventDefault());
  startInput?.addEventListener('keydown', (event) => event.preventDefault());

  startPicker = new Pikaday({
    field: startInput,
    format: 'DD.MM.YYYY',
    minDate: new Date(),
    firstDay: 1,
    i18n: i18nRus,
    onSelect: (date) => {
      endPicker.setMinDate(date);
      endPicker.gotoDate(date);
    },
  });

  endPicker = new Pikaday({
    field: endInput,
    format: 'DD.MM.YYYY',
    minDate: new Date(),
    firstDay: 1,
    i18n: i18nRus,
  });
};

const initSelects = () => {
  adultsSelect?.addEventListener('change', () => {
    if (adultsSelect!.value === '5') {
      adultsSelect.classList.add('long');
    } else {
      adultsSelect.classList.remove('long');
    }
  });

  childrenSelect?.addEventListener('change', () => {
    if (childrenSelect!.value === '5') {
      childrenSelect.classList.add('long');
    } else {
      childrenSelect.classList.remove('long');
    }
  });
};

const initChildren = () => {
  let initialChildren = 0;

  childrenSelect?.addEventListener('change', () => {
    const quantity = +childrenSelect.value;
    if (quantity > initialChildren) {
      for (let i = initialChildren; i < quantity; i++) {
        addChild(i);
      }
      initialChildren = quantity;
    }

    if (quantity < initialChildren) {
      for (let i = initialChildren; i > quantity; i--) {
        removeChild();
      }
      initialChildren = quantity;
    }

    if (quantity) {
      years?.classList.add('active');
    } else {
      years?.classList.remove('active');
    }
  });
};

const initYearsDropdown = () => {
  yearsBlock?.addEventListener('click', () => {
    if (yearsItems?.children.length) {
      yearsDropdown?.classList.toggle('active');
      quizWrapper?.classList.add('bigger');
      contacts?.classList.add('hide');
    }
  });
  yearsConfirm?.addEventListener('click', () => {
    yearsDropdown?.classList.remove('active');
    quizWrapper?.classList.remove('bigger');
    contacts?.classList.remove('hide');
  });
};

const initPrevButtons = () => {
  prevButton2?.addEventListener('click', () => {
    slider!.style.transform = 'translateX(0)';
    setStep(1);
  });
};

const initForm1 = () => {
  form1?.addEventListener('submit', (event) => {
    event.preventDefault();

    if (!startInput?.value) {
      startPicker.show();
      return;
    }

    if (!endInput?.value) {
      endPicker.show();
      return;
    }

    slider!.style.transform = 'translateX(-100%)';
    setStep(2);
  });
};

const initForm2 = () => {
  form2?.addEventListener('submit', (event) => {
    event.preventDefault();
    slider!.style.transform = 'translateX(-200%)';
    setStep(3);
  });
};

const getChildrenData = () => {
  if (!yearsItems?.children.length) {
    return '';
  } else {
    const items = Array.from(yearsItems.children);
    return items.map((item) => item.textContent).join(', ');
  }
};

const getSubmitFormData = () => {
  const source = document.body.dataset.source;

  const formData = new FormData();
  formData.append('form', 'quiz');
  formData.append('start', startInput!.value);
  formData.append('end', endInput!.value);
  formData.append('adults', adultsSelect!.value);
  formData.append('children', getChildrenData());
  formData.append('name', nameInput!.value);
  formData.append('phone', phoneInput!.value);
  formData.append('source', source || '');
  return formData;
};

const resetQuiz = () => {
  startInput!.value = '';
  endInput!.value = '';
  adultsSelect!.value = '2';
  childrenSelect!.value = '0';
  childrenSelect?.dispatchEvent(new Event('change'));
  yearsItems!.innerHTML = '';
  yearsContainer!.innerHTML = '';
  nameInput!.value = '';
  phoneInput!.value = '';
  checkbox!.checked = false;
};

const initSubmit = () => {
  form3?.addEventListener('submit', async (event) => {
    event.preventDefault();
    const formData = getSubmitFormData();
    await fetch('/ajax/_forms.php', {
      method: 'POST',
      body: formData,
    });

    ym(73424854, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3249506', type: 'reachGoal', goal: 'arrows' });

    resetQuiz();
    slider!.style.transform = 'translateX(0)';
    setStep(1);
    thanks?.classList.add('active');
    setTimeout(() => thanks?.classList.remove('active'), 3000);
  });
};

export default {
  init: () => {
    initDates();
    initSelects();
    initChildren();
    initYearsDropdown();
    initPrevButtons();
    initForm1();
    initForm2();
    initSubmit();
  },
};

import Pikaday from 'pikaday';
import { i18nRus } from './constants';
import { makeChild } from './quiz';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

const thanks = document.querySelector<HTMLDivElement>('.modal-thanks');
const modal = document.querySelector<HTMLDivElement>('.modal-booking');
const checkbox = modal?.querySelector<HTMLInputElement>(
  'input[type="checkbox"]'
);
const title = modal?.querySelector<HTMLHeadingElement>('.booking__title');
const subtitle =
  modal?.querySelector<HTMLParagraphElement>('.booking__subtitle');
const form = document.querySelector<HTMLFormElement>('.booking');
const startInput = document.querySelector<HTMLInputElement>(
  '.booking__start input'
);
const endInput = document.querySelector<HTMLInputElement>(
  '.booking__end input'
);
const adultsSelect = document.querySelector<HTMLSelectElement>(
  '.booking__adults select'
);
const childrenSelect = document.querySelector<HTMLSelectElement>(
  '.booking__children select'
);
const years = document.querySelector<HTMLDivElement>('.booking__years .years');
const yearsBlock = document.querySelector<HTMLDivElement>(
  '.booking__years .years__block'
);
const yearsDropdown = document.querySelector<HTMLDivElement>(
  '.booking__years .years__dropdown'
);
const yearsItems = document.querySelector<HTMLDivElement>(
  '.booking__years .years__items'
);
const yearsContainer = document.querySelector<HTMLDivElement>(
  '.booking__years .years__container'
);
const yearsConfirm = document.querySelector<HTMLDivElement>(
  '.booking__years .years__confirm'
);
const nameInput = document.querySelector<HTMLInputElement>(
  '.booking__name input'
);
const phoneInput = document.querySelector<HTMLInputElement>(
  '.booking__phone input'
);
const submitButton = form?.querySelector<HTMLButtonElement>('.booking__submit');

let startPicker: Pikaday;
let endPicker: Pikaday;

const initModal = () => {
  const screenButton =
    document.querySelector<HTMLButtonElement>('.screen__booking');
  const screenCalcButton =
    document.querySelector<HTMLButtonElement>('.screen__calculate');
  const roomButtons =
    document.querySelectorAll<HTMLButtonElement>('.rooms__booking');
  const roomCalcButtons =
    document.querySelectorAll<HTMLButtonElement>('.rooms__calculate');

  const handleClick = (_: MouseEvent, type: string, room?: string) => {
    title!.textContent =
      type === 'booking' ? 'Бронирование' : 'Расчет стоимости';
    submitButton!.textContent =
      type === 'booking' ? 'Забронировать' : 'Получить расчет';
    subtitle!.textContent = room ? room : '';
    subtitle!.style.height = room ? 'auto' : '0';
    modal?.classList.add('active');
    document.body.classList.add('overflow-hidden');
  };

  screenButton?.addEventListener('click', (event) =>
    handleClick(event, 'booking')
  );

  screenCalcButton?.addEventListener('click', (event) =>
    handleClick(event, 'calculate')
  );

  roomButtons.forEach((item) =>
    item.addEventListener('click', (event) =>
      handleClick(event, 'booking', item.dataset.name)
    )
  );

  roomCalcButtons.forEach((item) =>
    item.addEventListener('click', (event) =>
      handleClick(event, 'calculate', item.dataset.name)
    )
  );
};

const addChild = (num: number) => {
  yearsContainer?.appendChild(makeChild(num, yearsItems!));
  const span = document.createElement('span');
  span.textContent = '0';
  yearsItems?.appendChild(span);
};

const removeChild = () => {
  yearsContainer?.removeChild(yearsContainer.lastChild!);
  yearsItems?.removeChild(yearsItems.lastChild!);
  if (!yearsItems?.children.length) {
    yearsDropdown?.classList.remove('active');
  }
};

const initDates = () => {
  startInput?.addEventListener('keydown', (event) => event.preventDefault());
  endInput?.addEventListener('keydown', (event) => event.preventDefault());

  startPicker = new Pikaday({
    field: startInput,
    format: 'DD.MM.YYYY',
    minDate: new Date(),
    firstDay: 1,
    // reposition: false,
    // position: 'bottom left',
    container: startInput?.previousElementSibling as HTMLDivElement,
    i18n: i18nRus,
    onSelect: (date) => {
      endPicker.setMinDate(date);
      endPicker.gotoDate(date);
    },
  });

  endPicker = new Pikaday({
    field: endInput,
    format: 'DD.MM.YYYY',
    minDate: new Date(),
    firstDay: 1,
    container: endInput?.previousElementSibling as HTMLDivElement,
    i18n: i18nRus,
  });
};

const initSelects = () => {
  adultsSelect?.addEventListener('change', () => {
    if (adultsSelect!.value === '5') {
      adultsSelect.classList.add('long');
    } else {
      adultsSelect.classList.remove('long');
    }
  });

  childrenSelect?.addEventListener('change', () => {
    if (childrenSelect!.value === '5') {
      childrenSelect.classList.add('long');
    } else {
      childrenSelect.classList.remove('long');
    }
  });
};

const initChildren = () => {
  let initialChildren = 0;

  childrenSelect?.addEventListener('change', () => {
    const quantity = +childrenSelect.value;
    if (quantity > initialChildren) {
      for (let i = initialChildren; i < quantity; i++) {
        addChild(i);
      }
      initialChildren = quantity;
    }

    if (quantity < initialChildren) {
      for (let i = initialChildren; i > quantity; i--) {
        removeChild();
      }
      initialChildren = quantity;
    }

    if (quantity) {
      years?.classList.add('active');
    } else {
      years?.classList.remove('active');
    }
  });
};

const initYearsDropdown = () => {
  yearsBlock?.addEventListener('click', () => {
    if (yearsItems?.children.length) {
      yearsDropdown?.classList.toggle('active');
    }
  });
  yearsConfirm?.addEventListener('click', () => {
    yearsDropdown?.classList.remove('active');
  });
};

const getChildrenData = () => {
  if (!yearsItems?.children.length) {
    return '';
  } else {
    const items = Array.from(yearsItems.children);
    return items.map((item) => item.textContent).join(', ');
  }
};

const getSubmitFormData = () => {
  const source = document.body.dataset.source;

  const formData = new FormData();
  formData.append(
    'form',
    title!.textContent! === 'Бронирование' ? 'booking' : 'calculate'
  );
  formData.append('type', title!.textContent!);
  formData.append('room', subtitle!.textContent!);
  formData.append('start', startInput!.value);
  formData.append('end', endInput!.value);
  formData.append('adults', adultsSelect!.value);
  formData.append('children', getChildrenData());
  formData.append('name', nameInput!.value);
  formData.append('phone', phoneInput!.value);
  formData.append('source', source || '');
  return formData;
};

const resetForm = () => {
  startInput!.value = '';
  endInput!.value = '';
  adultsSelect!.value = '2';
  childrenSelect!.value = '0';
  childrenSelect?.dispatchEvent(new Event('change'));
  yearsItems!.innerHTML = '';
  yearsContainer!.innerHTML = '';
  nameInput!.value = '';
  phoneInput!.value = '';
  checkbox!.checked = false;
};

const initSubmit = () => {
  submitButton?.addEventListener('click', (event) => {
    if (!startInput?.value) {
      startPicker.show();
      event.preventDefault();
      return;
    }

    if (!endInput?.value) {
      endPicker.show();
      event.preventDefault();
      return;
    }
  });

  form?.addEventListener('submit', async (event) => {
    event.preventDefault();

    const formData = getSubmitFormData();
    await fetch('/ajax/_forms.php', {
      method: 'POST',
      body: formData,
    });

    ym(73424854, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3249506', type: 'reachGoal', goal: 'arrows' });

    resetForm();
    modal?.classList.remove('active');
    thanks?.classList.add('active');
    setTimeout(() => {
      thanks?.classList.remove('active');
      document.body.classList.remove('overflow-hidden');
    }, 3000);
  });
};

export default {
  init: () => {
    initModal();
    initDates();
    initSelects();
    initChildren();
    initYearsDropdown();
    initSubmit();
  },
};

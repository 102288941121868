import GLightbox from 'glightbox';

const initVideo = () => {
  const videoGallery = GLightbox({
    elements: [
      {
        href: 'https://jamaica-anapa.ru/jamaica-review.mov',
        type: 'video',
        source: 'local', //vimeo, youtube or local
        width: 1200,
      },
    ],
    autoplayVideos: true,
  });

  const image = document.querySelector('.gallery__video');
  image?.addEventListener('click', () => {
    videoGallery.open();
    console.log('cliock');
  });
};

const initZoom = () => {
  const lightbox = GLightbox({
    selector: '.gallery__image',
    loop: true,
  });
};

export default {
  init: () => {
    initVideo();
    initZoom();
  },
};
